import React, { useCallback, useEffect, useState } from 'react';
import { usePdvOnline } from '~/pages/PdvOnline/hooks/usePdvOnline';
import { Modal } from '..';
import { useForm } from 'react-hook-form';
import { InputText } from '~/components/NovosInputs';
import { BuscalEmailContainer } from './styles';
import { validaEmail } from '~/utils/functions';
import { toast } from 'react-toastify';
import { consultarVendasService } from '~/pages/PdvOnline/services/consulta-venda';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';

const MySwal = withReactContent(Swal);

export const ModalEnviarEmailComprovanteVenda: React.FC = () => {
  const { isModalOpen, onOpenModal, initialStatePdv } = usePdvOnline();
  const [loading, setLoading] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const {
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    watch,
    setFocus,
    formState: { errors },
  } = useForm();

  const { isOpen, modal, data } = isModalOpen;

  const modalOpen = isOpen && modal === 'enviarEmailComprovanteVenda';

  const num_pedido = data?.enviarEmailComprovanteVenda?.num_pedido;
  const cupom = data?.enviarEmailComprovanteVenda?.formData;
  const returnTo = data?.enviarEmailComprovanteVenda?.returnTo;

  const watchEmail: string = watch('des_email');

  useEffect(() => {
    if (!modalOpen || !initialStatePdv || !num_pedido) return;

    const { cod_loja, des_loja } = initialStatePdv;
    const loja = des_loja.split(' - ')[1];
    const assunto = `${loja} - Comprovante de venda #${num_pedido}`;

    const fetchClienteEmail = async () => {
      setLoading(true);
      const res = await consultarVendasService.indexEnviarEmail(
        num_pedido,
        cod_loja,
      );

      if (res.success) {
        const email = res.data || '';
        setValue('des_email', email);
        if (!email) setFocus('des_email');
      }
      setLoading(false);
    };

    fetchClienteEmail();
    setValue('des_assunto', assunto);
  }, [initialStatePdv, modalOpen, num_pedido, setFocus, setValue]);

  const handleCloseModal = useCallback(() => {
    onOpenModal(returnTo || 'funcoesPdv');
    setLoading(false);
    setSendingEmail(false);
  }, [onOpenModal, returnTo]);

  const showEmailConfigWarning = useCallback(async (message: string) => {
    const baseUrl = typeof window !== 'undefined' ? window.location.origin : '';
    const fullUrlEmail = `${baseUrl}/configuracao-de-e-mail`;
    const fullUrlVincularEmail = `${baseUrl}/vincular-email`;

    await MySwal.fire({
      title: message,
      html: `
        <style>
          .swal2-html-container a {
            color: #595959;
            text-decoration: underline;
            transition: color 0.3s ease;
          }
          .swal2-html-container a:hover {
            color: #007bff;
          }
        </style>
        <span>
          <p>
            Primeiro vá em
            <a href="${fullUrlEmail}" target="_blank" rel="noopener noreferrer">
              "CONFIGURAÇÃO DE E-MAIL"
            </a>, e cadastre seu email.
          </p>
          <p>
            Depois vá em
            <a href="${fullUrlVincularEmail}" target="_blank" rel="noopener noreferrer">
              "VINCULAR E-MAIL"
            </a>, e vincule o email à ação “EMISSÃO DE NFe/NFCe“.
          </p>
          <p>Por último volte aqui e tente novamente.</p>
        </span>
      `,
      icon: 'warning',
    });
  }, []);

  const isEmailValid = useCallback(
    (email: string): boolean => {
      if (email === '' || !validaEmail(email)) {
        setError('des_email', { type: 'required' });
        toast.warning('Verifique se o e-mail informado está correto');
        return false;
      }
      return true;
    },
    [setError],
  );

  const handleResponse = useCallback(
    async (response: any) => {
      if (!response.success) {
        setSendingEmail(false);

        if (response?.message?.includes('Email não configurado para o envio')) {
          await showEmailConfigWarning(response.message);
        } else toast.warning(response.message);

        return;
      }

      toast.success(response.message);
      setSendingEmail(false);
    },
    [showEmailConfigWarning],
  );

  const handleEnviar = useCallback(async () => {
    if (!modalOpen || !num_pedido || !initialStatePdv || !cupom) return;

    const email = getValues('des_email');

    if (!isEmailValid(email)) return;

    clearErrors('des_email');
    setSendingEmail(true);

    const { cod_loja } = initialStatePdv;
    const subject = getValues('des_assunto');

    const response = await consultarVendasService.storeEnviarEmail(
      cod_loja,
      email,
      subject,
      cupom,
    );

    await handleResponse(response);
  }, [
    clearErrors,
    cupom,
    getValues,
    handleResponse,
    initialStatePdv,
    isEmailValid,
    modalOpen,
    num_pedido,
  ]);

  useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      if (event.key === 'Enter' && modalOpen) await handleEnviar();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleEnviar, modalOpen]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && modalOpen) handleCloseModal();
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleCloseModal, modalOpen]);

  useEffect(() => {
    if (modalOpen) {
      setTimeout(() => {
        setFocus('des_email');
      }, 200);
    }
  }, [modalOpen, setFocus]);

  return (
    <Modal isOpen={modalOpen} title="Enviar e-mail" onClose={handleCloseModal}>
      <BuscalEmailContainer>
        <InputText
          label="Assunto:"
          name="des_assunto"
          register={register}
          isError={!!errors.des_assunto}
          disabled
          maxLength={300}
        />
        <InputText
          label="Email:"
          placeholder={loading ? 'Buscando email...' : 'Informe o email'}
          name="des_email"
          toLowerCase
          register={register}
          isError={!!errors.des_email}
          maxLength={100}
          disabled={loading || sendingEmail}
          onInput={(ev: any) => {
            const { value } = ev.target;
            setValue('des_email', value);
            clearErrors('des_email');
          }}
        />
        <div className="content-button">
          <button
            type="button"
            className="button-enviar-email"
            disabled={watchEmail === '' || loading || sendingEmail}
            onClick={async () => {
              await handleEnviar();
            }}
          >
            {sendingEmail ? 'Enviando...' : 'Enviar'}
          </button>
        </div>
      </BuscalEmailContainer>
    </Modal>
  );
};
